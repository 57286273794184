<template>
    <v-dialog v-model="showDialog" persistent  max-width="366px">
        <v-card flat>
          <v-card-title class="headline">
            Change Role
          </v-card-title>

          <v-divider class="mx-5"></v-divider>
            <v-alert
              :color="actionClass"
              dismissible
              v-if="message"
              dark
              >
              {{message}}
            </v-alert>
          <v-card-text>
            <v-radio-group v-model="role">
              <v-radio
                v-for="(item, index) in items"
                :key="index"
                :value="item.value"
              >
              <template v-slot:label>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title">{{ item.label }}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle">{{ item.label }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider class="mx-5"></v-divider>
          <v-card-actions class="pb-4 mt-1">
            <v-spacer></v-spacer>
            <v-btn text :color="`${sendyAzureTransporter30}`" class="action-btn" @click="close()" > Cancel </v-btn>
            <v-btn dark depressed  :color="`${sendyMidnightBlue20}`"  class="action-btn" @click="saveRole()" :loading="loading"> Save Changes </v-btn>
          </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import colorMxn from '@/mixins/color_mixins';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'changeLanguageModal',
  props: ['showDialog', 'selectedUser'],
  mixins: [colorMxn],
  data() {
    return {
      loading: false,
      items: [
        {
          value: 'superuser',
          label: 'Super User',
        },
        {
          value: 'admin',
          label: 'Admin',
        },
        {
          value: 'protocol',
          label: 'Protocol',
        },
        {
          value: 'user',
          label: 'user',
        },
      ],
      role: 'user',
      actionClass: 'success',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  watch: {
    selectedUser(val) {
      this.role = val.role;
    }
  },
  methods: {
    ...mapMutations(['setLanguage']),
    ...mapActions(['performUpdateActions']),
    close() {
      this.$emit('cancelDialog', false);
    },
    async saveRole() {
      this.loading = true;
      const payload = {
        role: this.role,
      };
       
       const fullPayload = {
         params: payload,
         endpoint: `/update/user/${this.selectedUser.id}`
       };

       const response = await this.performUpdateActions(fullPayload);
       console.log(response);
       this.actionClass = response.status ? 'success' : 'error';
       this.message = response.message;
       this.loading = false;
       if (response.status) {
         this.$emit('edited', true);
       }
    }
  },
};
</script>

<style scoped>
.card-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 36px;
}

.action-btn {
    display: block;
    width: 129px;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
}
</style>
